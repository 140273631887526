import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Segment, Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const AuthScreen = props => {

  return (
    <Grid
      style={{
        height: "72%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={!props.isLoaded ? "ghosted" : "unghosted"}
    >
      <Row className="center-xs">
        <Col style={{textAlign: "center"}}>
          <small>Captiv Sales</small>
          <h1 style={{margin:"1rem auto 1rem"}}>Digital Proposal Demo</h1>
          <Segment style={{ maxWidth: "92%", margin: "2em auto 0" }}>
            <p>Enter your name</p>
            <Form>
            <Form.Field
              style={{ marginBottom: 15, fontSize: "1.275rem" }}
            >
            <input id="loginName" type="text" placeholder='Name' />
            </Form.Field>
            <button type='submit' className="btn" style={{ fontSize: "1.275rem", width: "100%", marginTop: 0 }} id="loginBtn">Start</button>
            </Form>
          </Segment>
          <br/>
          <a id="loginAnon" href="#" style={{ color: "#000000", fontSize: "14px", textDecoration: "underline"}}>Continue Anonymously</a>
        </Col>
      </Row>
    </Grid>
  );
};

export default AuthScreen;

const varUse = {
  colors: {
    primary: "#00b500",
    primaryDark: "#00b500",
    secondary: "#000000",
    main: "#3d4b52",
    bg: "#ffffff",
    black: "#000000",
    white: "#ffffff",
  },
  typography: {
    fontFamily: `"Montserrat", "Source Sans Pro", "Open Sans", "Lato", "Arial", sans-serif`,
    color: "#000000",
    size: "1.65rem",
    sizeXS: ".5875rem",
    weight: "400",
    h1: {
      size: "4.5rem",
      weight: 300,
    },
    h2: {
      size: "2em",
      weight: 700,
      margin: "7.5px 0 15px 0",
    },
    h3: {
      size: "1.875em",
      weight: 500,
    },
    h4: {
      size: "1.5em",
      weight: 500,
    },
    list: {
      size: "100%",
    },
    blockquote: {
      fontFamily: `"GT Super", "Montserrat", "Source Sans Pro", "Open Sans", "Lato", "Arial", sans-serif`,
      size: "3.65rem",
      lineHeight: "1.125em",
      color: "#000000",
      sizeSmall: "2.875rem"
    }
  }
};

export default varUse
export const firebaseConfig = {
  master: {
    apiKey: "AIzaSyCQpdDsgoalJb75tBDLkm_boaeU4BRDCNY",
    authDomain: "captiv-proposal.firebaseapp.com",
    databaseURL: "https://captiv-proposal.firebaseio.com",
    projectId: "captiv-proposal",
    storageBucket: "captiv-proposal.appspot.com",
    messagingSenderId: "659421117824",
    appId: "1:659421117824:web:e433a0cce4bc158fead565",
    measurementId: "G-TJSTLRF0PR",
  },
  tsl: {
    apiKey: "AIzaSyBTCPE3U8afdsYfRFHL2BoODtRYkR9Y760",
    authDomain: "tsl-services.firebaseapp.com",
    databaseURL: "https://tsl-services.firebaseio.com",
    projectId: "tsl-services",
    storageBucket: "tsl-services.appspot.com",
    messagingSenderId: "489510382404",
    appId: "1:489510382404:web:7f303f899f01beea4bc511",
    measurementId: "G-96X62QNJLF",
  },
  use: {
    apiKey: "AIzaSyBKwwLiuHrJpw2T31bmjSS_8zyNdjsQ44w",
    authDomain: "use-captiv.firebaseapp.com",
    databaseURL: "https://use-captiv.firebaseio.com",
    projectId: "use-captiv",
    storageBucket: "use-captiv.appspot.com",
    messagingSenderId: "399026399937",
    appId: "1:399026399937:web:9950e0a83851e7d0364240",
    measurementId: "G-0HP18M9SWJ",
  },
};

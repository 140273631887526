const varTsl = {
  colors: {
    primary: "#009cd8",
    primaryDark: "#088ec3",  
    secondary: "#000000",
    main: "#3d4b52",
    bg: "#edecf7",
  },
  typography: {
    fontFamily: `"Source Sans Pro", "Open Sans", "Lato", "Arial", sans-serif`,
    size: "2em",
    h1: {
      size: "4.5rem",
      weight: 300,
    },
    h2: {
      size: "2.25em",
      weight: 500,
      margin: "7.5px 0 15px 0",
    },
    h3: {
      size: "1.875em",
      weight: 500,
    },
    h4: {
      size: "1.5em",
      weight: 500,
    },
  }
};

export default varTsl
// React
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Head
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";
import "./App.css";

// Firebase
import firebase from "firebase/app";
import * as config from "./common/firebaseConfig";
// import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";

// Reveal
import Reveal from "reveal.js";
import { revealInit } from "./reveal/init";
import slidesLocal from "./slides/components/slidesLocal";
import "./reveal/reset.css";
import "./reveal/reveal.css";
import "./reveal/opp.css";

// Components
import Header from "./control/Header";
import Footer from "./control/Footer";
import Slides from "./slides/Slides";
import AuthScreen from "./auth/AuthScreen";

// Style Components
import Theme from "./styled/Theme";
import { withTheme } from "styled-components";
import varTheme from "./styled/_var";

// Functions
import { windowResize } from "./slides/components/resize";

// Analytics
import { deviceDetect } from "react-device-detect";
import IPData from "ipdata";

// Fix this
ReactDOM.render(
  <div>
    <Favicon url="./lib/img/favicon.ico" />
  </div>,
  document.getElementById("root")
);

const thisTheme = "use";

// Initialize Firebase
firebase.initializeApp(config.firebaseConfig[thisTheme]);
// firebase.analytics();
firebase.performance();

const db = firebase.firestore();
const auth = firebase.auth();

// Firestore
const slides = db.collection("prop").doc("use");
const trackedUser = db.collection("trackedUser");

// Behavior tracking
let prevTimer, nextTimer;
let slidesToTrack = {};
let userData = {};

class App extends Component {
  constructor() {
    super();

    this.state = {
      draftMode: false,
      theme: thisTheme,
      title: "Smarter Proposals",
      isLoggedIn: false,
      isLoaded: false,
      isBuilt: false,
      userName: "",
      userEmail: "",
      currentSlide: "",
      auth: auth,
      slides: "",
      emailError: null,
      emailSuccess: false,
      uid: "",
      h: 0,
      v: 0,
      f: 0,
    };
  }

  componentDidMount() {
    // window.drift.on("ready", (api) => {
    //   api.widget.hide();
    // });

    windowResize();

    if (this.state.draftMode) {
      db.collection("styles").doc("theme").set(varTheme[this.state.theme]);
      slides.set(slidesLocal);
    } else {
      const ipdata = new IPData(
        "b014eb7210b86d85d5c6b3403ae40f24dc37794ef20cb2b63c05e4cd"
      );
      ipdata.lookup().then(function (info) {
        if (!info.message) {
          if (info.ip) {
            userData.ip = info.ip;
          }
          if (info.city) {
            userData.city = info.city;
          }
          if (info.region_code) {
            userData.state = info.region_code;
          }
          if (info.country_code) {
            userData.country = info.country_code;
          }
          if (info.continent_code) {
            userData.continent = info.continent_code;
          }
          if (info.latitude) {
            userData.lat = info.latitude;
          }
          if (info.longitude) {
            userData.long = info.longitude;
          }
          if (info.languages.name) {
            userData.lang = info.languages.name;
          }
          if (info.currency.code) {
            userData.currency = info.currency.code;
          }
          if (info.time_zone.current_time) {
            userData.time = info.time_zone.current_time;
          }
          if (info.threat) {
            userData.threat = info.threat;
          }
          if (info.count) {
            userData.count = info.count;
          }
          if (info.status) {
            userData.status = info.status;
          }
        }
      });
    }

    const device = deviceDetect();
    userData.device = device;

    const convert = () => {
      if (!this.state.draftMode) {
        trackedUser.doc(this.state.userName).set(
          {
            _converted: true,
          },
          { merge: true }
        );
      }
    };

    const login = () => {
      auth
        .signInAnonymously()
        .then(() => {
          console.log("Logged in successfully");
          this.setState({
            isLoggedIn: true,
          });
        })
        .catch((e) => {
          console.log("Login failed: " + e);
        });
    };

    // This can't be the best way to do this...
    const loginName = document.getElementById("loginName");
    const loginBtn = document.getElementById("loginBtn");
    const loginAnon = document.getElementById("loginAnon");

    if (loginBtn) {
      loginBtn.addEventListener("click", login);
    }
    if (loginAnon) {
      loginAnon.addEventListener("click", login);
    }

    auth.onAuthStateChanged((user) => {
      const initUserTracking = (user) => {
        const uid = user.uid;
        let name;
        const timestamp = new Date();

        // this is ugly. I just did it like this because I was using Firestore as a dashboard. We should save the records in a better way
        if (user.displayName) {
          name = `${user.displayName} - ${timestamp} - ${uid}`;
        } else {
          name = `${loginName.value} - ${timestamp} - ${uid}`;
        }

        if (loginName.value) {
          user
            .updateProfile({
              displayName: loginName.value,
            })
            .then(function () {
              // Not sure why I commented this out ??
              // 
              // firebase
              //   .analytics()
              //   .setUserProperties({ user_name: name, uid: uid });
            })
            .catch(function (error) {
              console.log("AnonAuthCatch: " + error);
            });
        }

        // I hate all of this code. This should probably be refactored

        // From here 
        this.setState({
          isLoggedIn: true,
          userName: name,
          uid: uid,
        });

        if (!this.state.draftMode) {
          trackedUser.doc(this.state.userName).set(userData, { merge: true });
        }

        let trackName, fName, lName;

        if (loginName.value) {
          trackName = loginName.value;
        } else {
          trackName = this.state.userName.split(" - ")[0];
        }

        if (trackName.split(" ")[0]) {
          fName = trackName.split(" ")[0];
        } else {
          fName = "John";
        }

        if (trackName.split(" ")[1]) {
          lName = trackName.split(" ")[1];
        } else {
          lName = "Doe";
        }

        // to here

        window.analytics.identify(uid, {
          name: trackName,
          traits: {
            firstName: fName,
            lastName: lName,
            city: userData.city,
            state: userData.state,
          },
        });
        window.drift.identify(trackName);
      };
      if (user) {
        if (!this.state.isBuilt) {
          initUserTracking(user);
          buildDeck();
        }
      } else {
        this.setState({
          isLoggedIn: false,
          isLoaded: true,
        });
      }
    });

    const handleSlideState = (event) => {
      let vp = Reveal.getRevealElement();
      let logo = document.getElementById("logo");
      let iconChat = document.getElementById("repChat");
      let playBtn = document.getElementById("playBtn");
      let pauseBtn = document.getElementById("pauseBtn");
      
      // This should probably be refactored into the data of each slide
      // Invert
      if (event.indexh === 2 || event.indexh === 3) {
        vp.classList.add("invert");
      } else {
        vp.classList.remove("invert");
      }

      // Hide logo
      if (Reveal.isFirstSlide() || Reveal.isLastSlide()) {
        logo.classList.add("unghostedMobile");
        logo.classList.remove("ghostedMobile");
      } else {
        logo.classList.add("ghostedMobile");
        logo.classList.remove("unghostedMobile");
      }

      // Chat
      if (event.indexh === 6) {
        iconChat.classList.add("blink");
        iconChat.classList.remove("blinkLong");
      } else {
        iconChat.classList.remove("blink");
        iconChat.classList.add("blinkLong");
      }

      // VO
      if (event.indexh === 7) {
        playBtn.classList.add("pulse");
        if (pauseBtn) {
          pauseBtn.classList.remove("pulse");
        }
      } else {
        playBtn.classList.remove("pulse");
        if (pauseBtn) {
          pauseBtn.classList.remove("pulse");
        }
      }
    };

    // Refactor this
    const buildDeck = () => {
      
      slides
        .get()
        .then((doc) => {

          this.setState({
            slides: doc.data().slides,
            isBuilt: true,
          });
          console.log("Deck Built");
          Reveal.initialize(revealInit());

          Reveal.on("ready", (event) => {
            windowResize();
            handleSlideState(event);

            prevTimer = Date.now();

            this.setIndex(event);

            this.setState({
              isLoaded: true,
              currentSlide: event.currentSlide.id,
            });

            // Yuck, need to track this better. Likely with segment
            const calendar = document.getElementById("calendar");
            if (calendar) {
              calendar.addEventListener("click", convert);
            }
          });

          Reveal.on("slidechanged", (event) => {
            windowResize();
            handleSlideState(event);
            this.setDuration(event);
            this.setIndex(event);

            // This is not functioning properly
            window.analytics.page(
              `${event.indexh}-${event.indexv} | ${
                event.currentSlide.id.charAt(0).toUpperCase() +
                event.currentSlide.id.slice(1)
              }`,
              {
                path: `/${event.currentSlide.id}`,
                title: event.currentSlide.id,
                indexh: event.indexh,
                indexv: event.indexv,
              }
            );
          });

          Reveal.on("slidetransitionend", (event) => {});
        })
        .catch((e) => {
          console.log("Error loading slides: " + e);
          window.location.reload();
        });
    };
  }

  logCurrentTrack(tracksToTrack) {
    if (!this.state.draftMode) {
      trackedUser.doc(this.state.userName).set(tracksToTrack, { merge: true });
    }
  }

  // This is a disaster I created to track slide duration. It should be refactored. We should probably write to segment instead of firebase
  setDuration(event) {
    if (!this.state.draftMode) {
      trackedUser
        .doc(this.state.userName)
        .get()
        .then((doc) => {
          if (doc) {
            slidesToTrack = doc.data();
            if (event.currentSlide) {
              let currSlidePosition = event.currentSlide.className
                .split("e")[1]
                .split(" ")[0];
              let currSlideId = event.currentSlide.id;
              let currSlide = `${currSlidePosition} | ${currSlideId}`;

              if (slidesToTrack) {
                if (!slidesToTrack[currSlide]) {
                  slidesToTrack[currSlide] = 0;
                }
              } else {
                slidesToTrack = {};
                slidesToTrack[currSlide] = 0;
              }

              let currSlideCount = `${currSlide}Count`;
              if (slidesToTrack[currSlideCount]) {
                slidesToTrack[currSlideCount] =
                  slidesToTrack[currSlideCount] + 1;
              } else {
                slidesToTrack[currSlide] = 1;
              }

              trackedUser
                .doc(this.state.userName)
                .set(slidesToTrack, { merge: true });
            }
          } else {
            trackedUser
              .doc(this.state.userName)
              .set(slidesToTrack, { merge: true });
          }
        });

      if (event.previousSlide) {
        let prevSlidePosition = event.previousSlide.className
          .split("e")[1]
          .split(" ")[0];
        let prevSlideId = event.previousSlide.id;
        let prevSlide = `${prevSlidePosition} | ${prevSlideId}`;

        nextTimer = Date.now();
        const slideDuration = (nextTimer - prevTimer) / 1000;
        prevTimer = Date.now();

        if (slidesToTrack[prevSlide]) {
          slidesToTrack[prevSlide] = Math.round(
            slidesToTrack[prevSlide] + slideDuration
          );
        } else {
          slidesToTrack[prevSlide] = Math.round(slideDuration);
        }

        trackedUser
          .doc(this.state.userName)
          .set(slidesToTrack, { merge: true });
      }
    }
  }

  setIndex(event) {
    const hash = event.currentSlide.id;
    // firebase.analytics().setCurrentScreen(hash);
    // firebase.analytics().logEvent("screen_view");
    // firebase.analytics().logEvent("page_view", { page: hash });
    this.setState({
      title: hash.charAt(0).toUpperCase() + hash.slice(1) + " | CaptivSales",
      currentSlide: hash,
      h: event.indexh,
      v: event.indexv,
    });
  }

  render() {
    const emailCaptureForm = document.getElementById("emailCaptureForm");
    const emailCaptureSuccess = document.getElementById("emailCaptureSuccess");
    const emailCaptureError = document.getElementById("emailCaptureError");

    if (this.state.emailSuccess) {
      emailCaptureForm.classList.add("hide");
      emailCaptureError.classList.add("hide");
      emailCaptureSuccess.classList.remove("hide");
    }
    if (this.state.emailError) {
      emailCaptureError.classList.remove("hide");
    }

    const submitEmail = () => {
      const loginEmail = document.getElementById("loginEmail");
      if (loginEmail) {
        if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(loginEmail.value)) {
          if (!this.state.emailSuccess) {
            window.analytics.identify(this.state.uid, {
              email: loginEmail.value,
              traits: {
                email: loginEmail.value,
              },
            });
            window.analytics.track("Converted");
            window.drift.identify(this.state.userName.split(" - ")[0], {
              email: loginEmail.value,
            });
          }
          trackedUser.doc(this.state.userName).set(
            {
              _email: loginEmail.value,
            },
            { merge: true }
          );
          this.setState({
            emailError: false,
            emailSuccess: true,
          });
          windowResize();
        } else {
          this.setState({
            emailError: true,
          });
          windowResize();
        }
      }
    };

    // This is just for show, does nothing
    const submitPassword = () => {
      const passwordSuccess = document.getElementById("passwordSuccess");
      passwordSuccess.classList.remove("hide");
    };

    if (this.state.isLoaded) {
      const loginEmailBtn = document.getElementById("loginEmailBtn");
      const loginPasswordBtn = document.getElementById("loginPasswordBtn");
      if (loginEmailBtn) {
        loginEmailBtn.addEventListener("click", submitEmail);
      }
      if (loginPasswordBtn) {
        loginPasswordBtn.addEventListener("click", submitPassword);
      }
    }

    return (
      <div className="reveal">
        <Helmet>
          <title>{this.state.title}</title>
        </Helmet>
        <Header />
        {this.state.isLoggedIn && this.state.slides && (
          <Slides
            slides={this.state.slides}
            userName={this.state.userName}
            isLoaded={this.state.isLoaded}
          />
        )}
        {this.state.isLoggedIn ? (
          <Footer
            h={this.state.h}
            v={this.state.v}
            userName={this.state.userName}
            currentTrack={this.state.currentSlide}
            logTrack={this.logCurrentTrack.bind(this)}
          />
        ) : (
          <AuthScreen isLoaded={this.state.isLoaded} auth={this.state.auth} />
        )}
        <Theme theme={varTheme[this.state.theme]} />
      </div>
    );
  }
}

export default withTheme(App);

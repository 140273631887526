import React, { Component } from "react";
import rep from "../lib/img/rep.png";
import Reveal from "reveal.js";
// eslint-disable-next-line
import { Howl, Howler } from "howler";

let tracksToTrack = {},
  startTimer = 0,
  endTimer = 0,
  deep = 0;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChatting: false,
      isPlaying: false,
      currentTrack: "",
      hasAudio: false,
      hasMessage: false,
    };

    this.handleChat = this.handleChat.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
  }

  componentDidMount() {
    this.openChat();


    window.drift.on("ready", (api) => {
      api.widget.hide()
      window.drift.on("message", () => {
        let widget = document.getElementById("drift-widget-container")
        widget.classList.add("ghosted")
        this.setState({
          hasMessage: true
        })
      })
      window.drift.on('chatClose', () => {
        api.widget.hide()
      })
    })

    let req;
    try {
      req = require(`../lib/sound/${this.props.h}-${this.props.v}.m4a`);
      const voTrack = new Howl({
        src: [req],
        onplay: () => {
          this.recordTrackStart();
        },
        onpause: () => {
          this.recordTrackDepth();
        },
        onstop: () => {
          this.recordTrackDepth();
        },
        onloaderror: () => {},
        onend: () => {
          this.recordTrackDepth();
          Reveal.next();
        },
      });
      this.setState({
        currentTrack: voTrack,
      });
    } catch (error) {
      console.log(error);
    }

    Reveal.on("slidechanged", () => {
      if (this.state.isPlaying) {
        this.stopAudio();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.h !== prevProps.h || this.props.v !== prevProps.v) {
      let req;
      try {
        req = require(`../lib/sound/${this.props.h}-${this.props.v}.m4a`);
        this.setState({
          hasAudio: true,
        });
      } catch (e) {
        this.setState({
          hasAudio: false,
        });
      }

      const voTrack = new Howl({
        src: [req],
        onplay: (e) => {
          this.recordTrackStart();
        },
        onpause: () => {
          this.recordTrackDepth();
        },
        onstop: () => {
          this.recordTrackDepth();
        },
        onloaderror: function () {},
        onend: () => {
          this.recordTrackDepth();
          Reveal.next();
        },
      });

      this.setState({
        currentTrack: voTrack,
      });
    }
  }

  openChat() {
    setTimeout(() => {
      const cta = document.querySelectorAll(".openChatBtn");
      for (const [index, value] of cta.entries()) {
        value.addEventListener("click", this.handleChat);
      }
    }, 3000);
  }

  recordTrackStart() {
    this.setState({
      isPlaying: true,
    });

    let playedTrack = `${this.props.h}-${this.props.v} || audio`;
    startTimer = Date.now();

    if (!deep) {
      tracksToTrack[playedTrack] = 0;
      this.logCurrentTrack(tracksToTrack);
    } else {
      tracksToTrack[playedTrack] += deep;
    }
  }

  recordTrackDepth() {
    this.setState({
      isPlaying: false,
    });

    let playedTrack = `${this.props.h}-${this.props.v} || audio`;
    endTimer = Date.now();

    deep += (endTimer - startTimer) / 1000;

    tracksToTrack[playedTrack] = Math.round(deep);

    window.analytics.track("Played", {
      duration: deep,
      h: this.props.h,
      v: this.props.v,
      id: this.props.currentTrack,
    })

    this.logCurrentTrack(tracksToTrack);
  }

  logCurrentTrack(tracksToTrack) {
    this.props.logTrack(tracksToTrack);
  }

  handleChat() {
    window.drift.api.openChat()
    let widget = document.getElementById("drift-widget-container")
        widget.classList.remove("ghosted")
    if (!this.state.isChatting) {
      let chatted = `${this.props.h}-${this.props.v} ||| chat`;
      tracksToTrack[chatted] = true;
      this.logCurrentTrack(tracksToTrack);
      window.analytics.track("Chat Opened", {
        h: this.props.h,
        v: this.props.v,
        id: this.props.currentTrack,
      })
    } 
    this.setState((prevState) => ({
      isChatting: !prevState.isChatting,
      hasMessage: false,
    })); 
  }

  handlePlay() {
    !this.state.isPlaying
      ? this.state.currentTrack.play()
      : this.state.currentTrack.pause();
    this.setState((prevState) => ({
      isPlaying: !prevState.isPlaying,
    }));
    const pauseBtn = document.getElementById("pauseBtn");
    const playBtn = document.getElementById("playBtn");
    if (!this.state.isPlayings) {
      if (playBtn) {
        playBtn.classList.remove("pulse");
      }
      if (pauseBtn) {
        pauseBtn.classList.remove("pulse");
      }
    }
  }

  stopAudio() {
    this.setState({
      isPlaying: false,
    });
    this.state.currentTrack.stop();
    deep = 0;
  }

  render() {
    return (
      <footer>
        <svg
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 100 100"
         height="25px"
         width="25px"
         className={this.state.hasMessage ? "hasMessage" : "hasMessage ghosted"}
         >
           <circle cx="50" cy="50" r="40" fill="red"/>
           <text>1</text>
        </svg>
        <img
          onClick={this.handleChat}
          src={rep}
          alt="Chat with Derek"
          id="rep"
          className="rep"
        />
        <svg
          onClick={this.handleChat}
          alt="Chat"
          id="repChat"
          className="rep ghosted blinkLong"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 209 209"
        >
          {" "}
          <path
            className="green"
            d="M104.5,0C46.8,0,0,46.8,0,104.5S46.8,209,104.5,209S209,162.2,209,104.5S162.2,0,104.5,0z"
          >
            {" "}
          </path>{" "}
          <path
            className="white"
            style={{ fill: "#FFFFFF" }}
            d="M104.5,54.6c10.4,0,19.9,2.1,28.6,6.2s15.6,9.8,20.7,16.8s7.7,14.8,7.7,23.3s-2.6,16.2-7.7,23.3 s-12,12.7-20.7,16.8s-18.2,6.2-28.6,6.2c-8.3,0-16.3-1.4-23.8-4.2c-3.9,3-7.9,5.4-12.2,7.3c-6.2,2.7-12.6,4-19.1,4 c-0.7,0-1.3-0.4-1.6-1.1s-0.2-1.4,0.2-2c1.3-1.3,3-3.5,5.1-6.5c3.6-5.2,5.9-10.1,7.1-14.7c-4-4.2-7.1-8.7-9.4-13.6 s-3.3-10.1-3.3-15.6c0-8.5,2.6-16.2,7.7-23.3s12-12.7,20.7-16.8S94.1,54.6,104.5,54.6z"
          ></path>{" "}
        </svg>

        <button
          onClick={this.handlePlay}
          id="playCon"
          data-playing="false"
          role="switch"
          aria-checked="false"
          className={this.state.hasAudio ? "unghosted" : "ghosted"}
        >
          {!this.state.isPlaying ? (
            <svg
              id="playBtn"
              className="play-pause"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 350 350"
            >
              <path
                d="M174.7,0C78.5,0,0,78.5,0,174.7C0,271.5,78.5,350,174.7,350c96.8,0,175.3-78.5,175.3-175.3
						C350,78.5,271.5,0,174.7,0z M174.7,315.5c-77.3,0-140.2-62.9-140.2-140.8c0-77.3,62.9-140.2,140.2-140.2
						c77.9,0,140.8,62.9,140.8,140.2C315.5,252.6,252.6,315.5,174.7,315.5z M258.1,175l-139.6,62.7V112.3L258.1,175L258.1,175z"
              />
            </svg>
          ) : (
            <svg
              id="pauseBtn"
              className="play-pause"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 350 350"
            >
              <path
                d="M123.5,106.8h35.1V243h-35.1V106.8z M190.9,106.8h35.1V243h-35.1V106.8z M174.7,0
						C78.5,0,0,78.5,0,174.7C0,271.5,78.5,350,174.7,350c96.8,0,175.3-78.5,175.3-175.3C350,78.5,271.5,0,174.7,0z M174.7,315.5
						c-77.3,0-140.2-62.9-140.2-140.8c0-77.3,62.9-140.2,140.2-140.2c77.9,0,140.8,62.9,140.8,140.2C315.5,252.6,252.6,315.5,174.7,315.5
						z"
              />
            </svg>
          )}
        </button>
      </footer>
    );
  }
}

export default Footer;

// import bgRepeat from "../lib/img/bg-repeat.png";

export const revealInit = () => {
    return {
        hash: true,
        history: true,
        disableLayout: true,
        // parallaxBackgroundImage: bgRepeat, // CSS syntax, e.g. "a.jpg"
        // parallaxBackgroundSize: "120px 103px", // CSS syntax, e.g. "3000px 2000px"
        // parallaxBackgroundRepeat: "repeat", // repeat/repeat-x/repeat-y/no-repeat/initial/inherit
        // parallaxBackgroundPosition: "center", // CSS syntax, e.g. "top left"
        // parallaxBackgroundHorizontal: 25,
        // parallaxBackgroundVertical: 25,
        pdfSeparateFragments: false,
        hideAddressBar: false,
        hideCursorTime: 2000,
        display: "flex",
      }
}
export const windowResize = () => {
  window.scrollTo(0, 0);
  // let vh = window.innerHeight * 0.01;
  // let reveal = document.getElementsByClassName("reveal")[0];
  // let slides = document.getElementsByClassName("slides")[0];
  // document.style.setProperty("--vh", `${vh}px`);
  // reveal.documentElement.style.setProperty("--vh", `${vh}px`);
  // slides.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    window.scrollTo(0, 0);
    // document.style.setProperty("--vh", `${vh}px`);
    // reveal.documentElement.style.setProperty("--vh", `${vh}px`);
    // slides.documentElement.style.setProperty("--vh", `${vh}px`);
  });
};


/* eslint-disable react/no-danger-with-children */
import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import renderHTML from "react-render-html";

class Slides extends Component {

  render() {
    console.log(this.props.slides);
    return (
      <div
        className={!this.props.isLoaded ? "slides ghosted" : "slides unghosted"}
      > 
        {this.props.slides.map((stack, i) => (
          <section key={"stack" + i.toString()} className={"stack" + i}>
            {stack.stack.map((slide, j) => (
              <section
                key={"section" + i.toString() + "-" + j.toString()}
                id={slide.link}
                // class={slide.class}
                className={"slide" + i + "-" + j}
                data-background-image={slide.bg}
              >
                <Grid fluid>
                  {/* {slide.bg.map((bg, jj) => {
                    const bgPattern = bg.src;
                    React.createElement(span, { className: c }, v);
                  })} */}
                  <Row>
                    {slide.cols.map((col, k) => (
                      <Col
                        key={"col" + i.toString() + "-" + j.toString() + "-" + k.toString()}
                        className={col.class}
                        xs={col.xs ? parseInt(col.xs) : 12}
                        sm={col.sm ? parseInt(col.sm) : parseInt(col.xs)}
                        md={col.md ? parseInt(col.md) : parseInt(col.xs)}
                        lg={col.lg ? parseInt(col.lg) : parseInt(col.xs)}
                      >
                        {col.blocks.map((div, l) => {
                          const f = div.format;
                          const v = div.value;
                          const t = div.type;
                          const c = div.class;
                          const a = div.action;
                          const id = div.id;
                          const key = "block-" + i.toString() + "-" + j.toString() + "-" + k.toString() + "-" + l.toString();
                          let personal = this.props.userName
                            .split(" ")[0]
                            .replace(/~/gi, "");

                          if (personal) {
                            personal = `${v}, ${personal}! Give us one business day to get back to you.`;
                          } else {
                            personal = `${v}!`;
                          }
                          let data;
                          let kids;
                          if (t === "text") {
                            data = React.createElement(f, { key: key, className: c, id: id }, v);
                          } else if (t === "list") {
                            kids = v.map((li, m) =>
                              React.createElement(
                                "li",
                                { key: "li" + m.toString(), className: c },
                                v[m]
                              )
                            );
                            data = React.createElement(f, { key: key, className: div.ulClass}, kids);
                          } else if (t === "divider") {
                            data = <hr />;
                          } else if (t === "svg") {
                            data = renderHTML(v);
                          } else if (t === "html") {
                            data = renderHTML(v);
                          } else if (t === "image") {
                            data = React.createElement(f, { key: key, src: v, className: c})
                          } else if (t === "personal") {
                            data = React.createElement(f, { key: key, id: id, className: c}, personal);
                          } else if (t === "link") {
                            data = React.createElement(
                              f,
                              { key: key,  href: a, className: c, id: id, target: "_blank" },
                              v
                            );
                          } else if (t === "form") {
                            data = React.createElement(f, { key: key, id: "emailCaptureForm"}, div.fields.map((d, n) => {
                              let thisInput
                              if (d.placeholder) {thisInput = React.createElement(d.format, { key: key + n, id: d.id, placeholder: d.placeholder, className: "captureEmail", type: "text"})}
                              if (d.value) {thisInput = React.createElement(d.format, { key: key + n, id: d.id, className: "btn", type: "submit"}, d.value)}
                              return thisInput
                            }))
                          }
                          return data;
                        })}
                      </Col>
                    ))}
                  </Row>
                </Grid>
              </section>
            ))}
          </section>
        ))}
      </div>
    );
  }
}

export default Slides;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
  --background-color: ${ props => props.theme.colors.bg};
  --main-font: ${ props => props.theme.typography.fontFamily};
  --main-font-size: ${ props => props.theme.typography.size};
  --main-color: ${ props => props.theme.colors.main};
  --heading-font: ${ props => props.theme.typography.fontFamily};
  --heading-color: ${ props => props.theme.colors.primary};
  --link-color: ${ props => props.theme.colors.primary};
}

button {
  font-family: ${ props => props.theme.typography.fontFamily};
}

.reveal {
  font-family: ${ props => props.theme.typography.fontFamily};
  font-size: ${ props => props.theme.typography.size};
  color: ${ props => props.theme.colors.main};
  font-weight: ${ props => props.theme.typography.weight};
}

@media (max-width: 350px) {
  html {
    font-size: ${ props => props.theme.typography.sizeXS};
  }
  .slides {
    position: relative;
    top: -12px;
  }
}

.reveal ::selection {
  color: #fff;
  background: ${ props => props.theme.colors.primaryDark};
  text-shadow: none;
}

.reveal ::-moz-selection {
  color: #fff;
  background: ${ props => props.theme.colors.primaryDark};
  text-shadow: none;
}

.reveal h1,
.reveal h2,
.reveal h3,
.reveal h4,
.reveal h5,
.reveal h6,
.reveal p,
.reveal li {
  color: ${ props => props.theme.colors.black};
  font-family: ${ props => props.theme.typography.fontFamily};
}

.reveal h1 {
  font-family: ${ props => props.theme.typography.h1.fontFamily};
  font-size: ${ props => props.theme.typography.h1.size};
  font-weight: ${ props => props.theme.typography.h1.fontWeight};
  text-shadow: none;
}

.reveal h2 {
  font-size: ${ props => props.theme.typography.h2.size};
  font-weight: ${ props => props.theme.typography.h2.weight};
  margin: 7.5px 0 15px 0;
}

.reveal h3 {
  font-size: ${ props => props.theme.typography.h3.size};
  font-weight: ${ props => props.theme.typography.h3.weight};
}

.reveal h4 {
  font-size: ${ props => props.theme.typography.h4.size};
  font-weight: ${ props => props.theme.typography.h4.weight};
}

.reveal a {
  color: ${ props => props.theme.colors.primary};
  text-decoration: none;
  transition: color 0.15s ease;
  cursor: pointer !important;
}

.reveal a:hover {
  color: ${ props => props.theme.colors.primary};
  text-shadow: none;
  border: none;
}

.reveal ul {
  font-size: ${ props => props.theme.typography.list.size};
}

.reveal blockquote {
  font-size: ${ props => props.theme.typography.blockquote.size};
  font-family: ${ props => props.theme.typography.blockquote.fontFamily};
  color: ${ props => props.theme.typography.blockquote.color};
  line-height: ${ props => props.theme.typography.blockquote.lineHeight};
}

@media (max-width: 767px) {
  .reveal blockquote {
    font-size: ${ props => props.theme.typography.blockquote.sizeSmall};
  }
}

.reveal .controls {
  color: ${ props => props.theme.colors.primary};
}

.reveal.invert .controls {
  color: ${ props => props.theme.colors.white};
}

.reveal .progress {
  background: rgba(0, 0, 0, 0.2);
  color: ${ props => props.theme.colors.primary};
  position: fixed
}

.reveal.invert .progress {
  background: rgba(255, 255, 255, 0.2);
  color: ${ props => props.theme.colors.white};
  position: fixed
}

#logo {
  text-align: left;
}

#logo-svg {
  width: 60px;
  fill: ${ props => props.theme.colors.primary};
}

.invert #logo-svg {
  width: 60px;
  fill: ${ props => props.theme.colors.white};
}

.rep path.green {
  fill: ${ props => props.theme.colors.primary} !important;
}

.rep path.white {
  fill: #ffffff;
}

.play-pause {
  width: 52px;
  height: 52px;
  transition: all 0.25s;
  fill: ${ props => props.theme.colors.primary};
}

.invert .play-pause {
  fill: ${ props => props.theme.colors.white};
}

.btn {
  color: #fff !important;
  border: 0.1em solid ${ props => props.theme.colors.primary};
  background-color: ${ props => props.theme.colors.primary};
}

.btn:hover {
  border: 0.1em solid ${ props => props.theme.colors.primaryDark} !important;
  background-color: ${ props => props.theme.colors.primaryDark};
}

.voIcon {
  position: relative;
  top: 40px;
  left: -15px;
}

.voIconSmall {
  position: relative;
  top: 17.5px;
  left: -15px;
}

@media (max-width: 767px) {
  .voIconSmall {
  top: 7.5px;
}
}

.reveal .colorSuccess {
  color: ${ props => props.theme.colors.primary};
  font-weight: bold;
  font-size: 1.15rem;
  position: absolute;
}

.colorError {
  color: red;
  font-weight: bold;
  font-size: 1.15rem;
  position: absolute;
}



`

export default GlobalStyle;
